export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "terrahub-resources"
    },
    apiGateway_admin: {
        REGION: "us-east-1",
        URL: "https://q7msi12zsk.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_indv: {
        REGION: "us-east-1",
        URL: "https://u5by0f2hii.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_indv_credentials: {
        REGION: "us-east-1",
        URL: "https://2prjgylzk6.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_indv_dailychecks: {
        REGION: "us-east-1",
        URL: "https://axr7jmlyzg.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_indv_documents: {
        REGION: "us-east-1",
        URL: "https://7x7593j6t4.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_indv_forms: {
        REGION: "us-east-1",
        URL: "https://jiqgvkzx88.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_integrations: {
        REGION: "us-east-1",
        URL: "https://e03xyz8718.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org: {
        REGION: "us-east-1",
        URL: "https://rwuzjwb5s7.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_individuals: {
        REGION: "us-east-1",
        URL: "https://zxk9upkr9e.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_credentials: {
        REGION: "us-east-1",
        URL: "https://85b52h4ttb.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_dailychecks: {
        REGION: "us-east-1",
        URL: "https://thdta8mtke.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_documents: {
        REGION: "us-east-1",
        URL: "https://oe9c8uq3t4.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_forms: {
        REGION: "us-east-1",
        URL: "https://6pphagpx1f.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_locations: {
        REGION: "us-east-1",
        URL: "https://kwhwq1ypi7.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_onsites: {
        REGION: "us-east-1",
        URL: "https://3nv9bs15i9.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_orgcredentials: {
        REGION: "us-east-1",
        URL: "https://vkieaixk1c.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_requirements: {
        REGION: "us-east-1",
        URL: "https://p5edf63w21.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_org_wallet: {
        REGION: "us-east-1",
        URL: "https://m14q55fgo2.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_verifiable_credentials: {
        REGION: "us-east-1",
        URL: "https://9lu2nsfspg.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_translation: {
        REGION: "ca-central-1",
        URL: "https://api.credivera.com/translation"
    },
    apiGateway_scanner: {
        REGION: "us-east-1",
        URL: "https://dlasw02uh1.execute-api.us-east-1.amazonaws.com/qa"
    },
    apiGateway_storage: {
        REGION: "us-east-1",
        URL: "https://u0m3wbb9d6.execute-api.us-east-1.amazonaws.com/qa"
    },
    partner_api_domain: {
        URL: "https://qa.api.credivera.com"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_4Yg1w2BYQ",
        IDENTITY_POOL_ID: "us-east-1:0064ebbd-de3b-4299-8834-f3b35b629c78",
        APP_CLIENT_ID: "148kn4e1mic5h5th94od4jk334",
        redirect_uri: 'https://9lluvtnqp9.execute-api.us-east-1.amazonaws.com/qa/get-saml/',
        client_id: '8un3d1dmg535psg501btnqr1k',
        oauth_fetch: 'https://b4f66b02-f349-4a12-b6b3-d1e1592a8357.auth.us-east-1.amazoncognito.com/oauth2/token',
        org_id: 'E781F687-E755-45A8-9420-63A7A0E1E55F',
        idp: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_4Yg1w2BYQ',
        post_indiv: 'https://41bcjqigv1.execute-api.us-east-1.amazonaws.com/qa/post-individual-org/'
    },
    language: {
        en: {
            name: "English",
            i18n: "en-US",
            moment: "en-ca",
            datepicker: "en-US"
        },
        fr: {
            name: "French",
            i18n: "fr-CA",
            moment: "fr-ca",
            datepicker: "fr-FR"
        }
    },
    general: {
        alpha_indiv_url: 'https://my-qa.credivera.com'
    }
};
